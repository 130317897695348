(function ( $ ) {
     $.fn.menuLink = function (options) {
         var settings = $.extend({
            someOption: ""
        }, options);

        var objLink    = $(this);
        var nav        = $('.nav');
        var opened     = false;

        var didScroll;
        var lastScrollTop = 0;
        var delta = 200;

        function _init() {
            _click(objLink);
            if(!$( "#head" ).hasClass( "noFix" )){
                _initScroll();
            }
        }

        function _initScroll(){
            $(window).scroll(function(event){
                  didScroll = true;
            });

            setInterval(function() {
                 if (didScroll) {
                     hasScrolled();
                     didScroll = false;
                 }
             }, 1000);
        }

        function hasScrolled() {
            scroll = 1000;
            if($(this).scrollTop() > scroll){
                $('#head').addClass('fixed');
            }else{
                $('#head').removeClass('fixed');
            }
        }

        function _click(o){
            $(o).click(function(e) {
                if(opened === false){
                    opened  = true;
                    _openNavigation();
                }else{
                    opened = false;
                    _closeNavigation();
                }
            });
        }

        function _closeNavigation(){
            $(nav).addClass('close');
            //$(objLink).find('i').addClass('icon-menu').removeClass('icon-close');
            $('body').removeClass('locked');
        }

        function _openNavigation(){
            $(nav).addClass('active').removeClass('close');
            lastScrollTop = $(window).scrollTop();
            setTimeout(function(){ $('body').addClass('locked'); }, 300);
            //$(objLink).find('i').removeClass('icon-menu').addClass('icon-close');
        }

        _init();

       return {
         reload:_init,
       };
    };
}( jQuery ));

$("a.mobilNav").menuLink();
